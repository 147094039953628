import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import ContactForm from "./ContactForm";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const FeatureGrid = ({ gridItems }) => (
        <div className="columns is-multiline">
            {gridItems.map(item => {
                item.text = remark()
                    .use(recommended)
                    .use(remarkHtml)
                    .processSync(item.text).toString();
                return (
                    <div key={item.text} className="column is-6">
                        {item.text.indexOf("CONTACT_FORM") === -1 && <section className="section">
                            <div className="has-text-centered">
                                <div
                                    style={{
                                        width: '320px',
                                        display: 'inline-block',
                                    }}
                                >
                                    <PreviewCompatibleImage imageInfo={item}/>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{__html: item.text}}/>
                        </section>}
                        {item.text.indexOf("CONTACT_FORM") !== -1  && <section className="section"><ContactForm/></section>}
                    </div>
                );
            })}
        </div>
    )

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
