import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import ContactForm from "./ContactForm";

const Footer = class extends React.Component {
  render() {
    return (
        <React.Fragment>
          <div className="columns">
            <div
                className="column is-offset-one-quarter footer-pitch is-half has-text-weigt-semibold is-size-2 has-text-centered">
              Book your FREE Hypnosis Screening today. Contact us at <a href="tel:289-440-6762">289-440-6762</a>
            </div>
          </div>
          <footer className="footer has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column has-text-centered" style={{marginLeft: '1rem'}}>
                <div className="content">
                  <img
                      src={logo}
                      alt="Green Willow Self Hypnosis Logo"
                      style={{width: '14em', height: '10em', marginTop: '-2rem'}}
                  />
                </div>
                <p>*THE CLIENT ENDORSEMENTS SHOWN ARE ONLY THE EXPERIENCE OF THE CLIENT. RESULTS ARE NOT TYPICAL.</p>
                <p>**ENDORSEMENTS PRODUCED BY CLINICS USING THE MASTER HYPNOTICS SOCIETY METHOD OF HYPNOSIS
                  TRAINING.</p>
                <br/>
                <p>&copy; Copyright Green Willow Self Hypnosis 2020. All rights reserved.</p>
                <br/>
                <p>Green Willow Self Hypnosis centre offers training in self-hypnosis for self-help. Green Willow Self
                  Hypnosis is not a medical facility and does not diagnose or treat medical conditions. The services
                  provided is for educational purposes only. It is not therapy and is not meant as a replacement for any
                  other interventions. Please note individual results may vary.</p>
                <br/>
                <p>2019 Managed by GI Consultants.</p>
              </div>
              <div className="column has-text-centered">
                <p>Important Links</p>
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/weightloss">
                        Weight loss
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/quit-smoking">
                        Quit Smoking
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/stress-management">
                        Stress Management
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/unwanted-habits">
                        Unwanted Habits
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/your-success">
                        Your Success
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/sitemap">
                        Sitemap
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column has-text-centered" style={{marginRight: '1em'}}>
                <p>To Reach Us</p>
                <div className="social">
                  <a title="facebook" rel="noopener noreferrer" href="https://www.facebook.com/GreenWillowSelfHypnosis"
                     target="_blank">
                    <img
                        src={facebook}
                        alt="Facebook"
                        style={{width: '1em', height: '1em'}}
                    />
                  </a>
                  {/*<a title="twitter" rel="noopener noreferrer" href="https://twitter.com/greenwillowsel1"
                     target="_blank">
                    <img
                        className="fas fa-lg"
                        src={twitter}
                        alt="Twitter"
                        style={{width: '1em', height: '1em'}}
                    />
                  </a>*/}
                  <a title="instagram" rel="noopener noreferrer"
                     href="https://www.instagram.com/greenwillowselfhypnosis/" target="_blank">
                    <img
                        src={instagram}
                        alt="Instagram"
                        style={{width: '1em', height: '1em'}}
                    />
                  </a>
                </div>
                <p><a href="tel:289-440-6762">289-440-6762</a></p>
                <Link to="/contact">
                  Book Your FREE Screening
                </Link><br/>
                <Link to="/contact">
                  Contact Us
                </Link>
                <ContactForm type="footer"/>
              </div>
            </div>
            <div className="content has-text-centered has-background-black has-text-white-ter">
              <div className="container has-background-black has-text-white-ter">
                <div className="columns">
                  <div className="column is-4">

                  </div>
                  <div className="column is-4">
                    <section>
                      <ul className="menu-list">
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </React.Fragment>
    );
  }
}

export default Footer
