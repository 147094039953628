import React from 'react'
import {Link} from 'gatsby'
import logo from '../img/logo.svg'

const TopBanner = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
        }
    }

    render() {
        return (
            <div className="container">
                <div className="topbar columns">
                    <div className="column first">
                        <Link to="/" className="logo" title="Logo">
                            <img src={logo} alt="GWSH Logo" style={{width: '230px'}}/>
                        </Link>
                    </div>
                    <div className="column middle is-three-fifths">
                        <p>The Magic is in the Actions You Take</p>
                    </div>
                    <div className="column last">
                        <div>Call <a href="tel:289-440-6762">289-440-6762</a></div>
                        <div>For Your <strong>FREE</strong></div>
                        <div>Hypnotic Screening</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopBanner
